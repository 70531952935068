body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  background-color: black;
}

html, body {
  height: 100%;
}

* {
  user-select: none;
}

.folder-browser {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.folder-browser .folders {
  margin-bottom: 5px;
}

.folder-browser .folders .card {
  flex-grow: 0;
  min-width: auto;
  margin-bottom: 5px;
}

.folder-browser .images {
  margin: auto;
}

.folder-browser .images .card {
  max-width: 242px;
  min-width: 242px;
  height: 242px;
  margin: 5px;
}

.folder-browser .folders .card.level-up {
  font-size: 200%;
  line-height: 50%;
}

.note {
  padding-top: 10%;
  text-align: center;
  color: white;
}